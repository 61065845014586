import {makeSetters} from '@helpers/vuex/mutations'
import {flattenEntityForest} from '@helpers/tree'
import axios from 'axios'
import moment from 'moment'

export default {
  namespaced: true,
  state: {
    preferences: null,
    entityForest: [],
    companyIds: [],
    seenFirstTimeHint: true // disabled for agritechnica
  },
  getters: {
    orgForest (state) {
      const cloneOrg = org => ({
        ...org,
        isCompany: state.companyIds.includes(org.id),
        children: org.children.filter(x => x.type === 'org').map(cloneOrg)
      })

      return state.entityForest.map(cloneOrg)
    },
    orgUnits (state, getters) {
      return flattenEntityForest(getters.orgForest)
    },
    parentLookup (state, getters) {
      const lookup = {}
      getters.orgUnits.forEach(orgUnit => {
        orgUnit.children.forEach(child => {
          lookup[child.id] = orgUnit
        })
      })
      return lookup
    }
  },
  mutations: {
    ...makeSetters([
      'selectedCompany',
      'seenFirstTimeHint',
      'entityForest',
      'companyIds',
      'preferences'
    ])
  },
  actions: {
    loadPrerequisiteData ({dispatch}) {
      return Promise.all([
        dispatch('loadForest'),
        dispatch('loadCompanies'),
        dispatch('loadPreferences')
      ])
    },
    async loadForest ({state, commit}) {
      const harvestYear = moment().year() // the Fields of each company can change during Years. thats why this api should get the tree of each year. (also last two years). and create new ui.

      const response = await axios.get(`/api/v2/entities/${harvestYear}`)

      commit('setEntityForest', response.data)
    },
    async loadCompanies ({state, commit}) {
      const response = await axios.get('/api/v2/entities/companies')

      commit('setCompanyIds', response.data)
    },
    async loadPreferences ({commit}) {
      const response = await axios.get('/api/v2/dlg-cert/preferences')

      commit('setPreferences', response.data)
    },
    async persistSelectedOrgUnitId ({state, commit}, id) {
      const oldPreferences = state.preferences

      commit('setPreferences', {...state.preferences, selectedOrgUnitId: id})

      try {
        await axios.put('/api/v2/dlg-cert/preferences', state.preferences)
      } catch (error) {
        console.error(error)
        commit('setPreferences', oldPreferences)
      }
    }
  }
}
